* {
    margin: 0;
    padding: 0;
}

.main-card {
    color: rgb(221, 221, 221);
    height: 220px;
    background: black;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Profile-Picture {
    height: 220px;
    object-fit: cover;
    overflow: hidden;
}

.main-card-Business {
    color: rgb(221, 221, 221);
    height: 220px;
    background: transparent;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Business-Picture {
    height: 220px;
    background-color: white;
    overflow: hidden;
}


.name-surname {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 5vw;
}

.person-title {
    padding-left: 20px;
    padding-top: 10px;
    color: rgb(101, 102, 102);
    font-size: 4vw;
}


.name-surname-desktop {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20pt;
}

.person-title-desktop {
    padding-left: 20px;
    padding-top: 10px;
    color: rgb(101, 102, 102);
    font-size: 14pt;
}


.links-card {

    color: rgb(221, 221, 221);
    width: 35%;
    min-width: 500px;
    height: 100px;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    background: rgb(46, 46, 46);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none
}

.links-card-2 {
    color: rgb(221, 221, 221);
    width: 235px;
    height: 100px;
    margin-top: 20px;
    margin-left: 5%;
    background: rgb(45, 45, 45);
    border-radius: 10px;

    overflow: hidden;
    text-decoration: none
}

.mid-buttons {

    display: flex;
    justify-content: flex-start;

}

a:hover {
    box-shadow: 0 0 10px rgba(84, 156, 251, 0.7);
}

.link-title {
    margin-left: 30px;
    margin-top: 5%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 20px;
}

.link-icon {
    width: 20px;
}