.main-card-Business {
    color: rgb(221, 221, 221);
    height: 170px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Profile-Picture-Business {
    height: 170px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}





.main-card-Company {
    color: rgb(221, 221, 221);
    height: 250px;
    background: black;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Profile-Picture-Company {
    height: 170px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.card-title-Company {
    height: 220px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.name-surname-Company {
    padding-top: 5px;
    text-align: center;
    font-size: 13pt;
}

.person-title-Company {
    padding-bottom: 5px;
    text-align: center;
    color: rgb(101, 102, 102);
    font-size: 10pt;

}

.name-surname-desktop-Company {
    padding-top: 10px;
    font-size: 14pt;
    text-align: center;
}

.person-title-desktop-Company {
    padding-top: 5px;
    padding-bottom: 10px;
    color: rgb(101, 102, 102);
    font-size: 11pt;
    text-align: center;
}